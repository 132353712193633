





















































import {defineComponent, PropType} from '@vue/composition-api';
import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import TableInput from '@/tasks/components/inputs/TableInput.vue';
import {VTextField} from 'vuetify/lib/components';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default defineComponent({
  name: 'uOttawa1321Lab4Question3',
  components: {TableInput, STextarea, CalculationInput, VTextField},
  mixins: [DynamicQuestionMixin()],
  props: {
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      inputs: {
        aiQuestionResponse1: null,
        aiQuestionResponse2: null,
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
      questions: [
        {
          en: 'When butanoic acid reacts with sodium borohydride, 1-butanol IS NOT obtained. However, bubbling is still observed and heat is produced.',
          fr: "Lorsque l'acide butanoïque réagit avec le borohydrure de sodium, du 1-butanol N'EST PAS obtenu. Cependant, on observe toujours des bulles et de la chaleur est produite.",
        },
        {
          en: 'a) Why is 1-butanol not obtained?',
          fr: "a) Pourquoi le 1-butanol n'est-il pas obtenu ?",
        },
        {
          en: 'b) What is/are the product(s) of this reaction?',
          fr: 'b) Quel(s) est/sont le(s) produit(s) de cette réaction ?',
        },
      ],
    };
  },
  methods: {
    getQuestionText(question: any): string {
      return this.inputs.language === 'en' ? question.en : question.fr;
    },
  },
});
